<template>
  <v-container
    fluid
    class="px-0 py-10"
    style="
      height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-data-table
      :headers="headers"
      :items="assets"
      :items-per-page="25"
      class="elevation-0 mx-lg-10"
      mobile-breakpoint="100"
      calculate-widths
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-8 py-8"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              color="purple"
              single-line
              hide-details
              clearable
              @keyup.enter.native="filterData"
              @click:append="filterData"
              @click:clear="filterClear"
            />
          </v-col>
          <v-spacer />
        </v-row>
      </template>

      <template v-slot:footer>
        <div class="text-center my-5 py-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="purple"
            @input="onChangePage"
          />
          <span style="font-size: 12px; font-style: italic;">
            Page {{ page }} of {{ pageCount || 1 }}
          </span>
        </div>
        <v-row class="mt-n16">
          <v-col class="text-right pr-8">
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {},
    data: () => ({
      search: '',
      headers: [
        { text: 'Nama Badan', align: 'name_legal', value: 'name', sortable: false },
        { text: 'Nama Merek', value: 'name', sortable: false },
        { text: 'Aktif', value: 'per_status[0].count', sortable: false, align: 'center' },
        { text: 'Tidak Aktif', value: 'per_status[1].count', sortable: false, align: 'center' },
        { text: 'Proses Verifikasi', value: 'per_status[2].count', sortable: false, align: 'center' },
        { text: 'Verifikasi Gagal', value: 'per_status[3].count', sortable: false, align: 'center' },
        { text: 'Draft', value: 'per_status[4].count', sortable: false, align: 'center' },
        { text: 'Blokir', value: 'per_status[5].count', sortable: false, align: 'center' },
        { text: 'Total Aset', value: 'total', sortable: false, align: 'center' },
      ],
      assets: [],

      // Section Pagination
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      async initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('EMPLOYEE_CREATE')) {
        //   this.isAllowCreate = true
        // }

        const vm = this
        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }
        await axios.post('/t/asset/recap', requestBody).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.data)
            this.assets = res.data.data.list
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        }).catch((e) => {
        })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>
